/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { Line } from "rc-progress";
import axios from "axios";
import ReactLoading from "react-loading";
import { v4 as uuidv4 } from "uuid";

function NewHome() {
  let [isOpen, setIsOpen] = useState(false);
  let [openUpload, setOpenUpload] = useState(false);
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState([]);
  const [participantInfo, setParticippantInfo] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: "",
    nationality: "",
  });
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [code, setCode] = useState();
  console.log(progress);

  useEffect(() => {
    setIsOpen(true);
    axios
      .get(
        "https://maindashbe-june-b18731a0e161.herokuapp.com/api/val-discount-today/"
      )
      .then((res) => {
        console.log(res);
        setCode(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function isNumberGreaterThanCurrentDayInFebruary(number) {
    // Get the current date
    const currentDate = new Date();

    // Check if the current month is February (month is 0-indexed, so February is 1)
    const isFebruary = currentDate.getMonth() === 1;

    if (number === 1) {
      return false;
    } else if (isFebruary) {
      // Get the current day of the month
      const currentDay = currentDate.getDate();

      // Check if the input number is greater than the current day
      return number > currentDay;
    }

    // If it's not February, return false
    return true;
  }
  function behind(number) {
    // Get the current date
    const currentDate = new Date();

    // Check if the current month is February (month is 0-indexed, so February is 1)
    const isFebruary = currentDate.getMonth() === 1;

    if (isFebruary) {
      // Get the current day of the month
      const currentDay = currentDate.getDate();

      // Check if the input number is greater than the current day
      return number < currentDay;
    }

    // If it's not February, return false
    return false;
  }
  function current(number) {
    // Get the current date
    const currentDate = new Date();

    // Check if the current month is February (month is 0-indexed, so February is 1)
    const isFebruary = currentDate.getMonth() === 1;

    if (number === 1) {
      return true;
    }

    if (isFebruary) {
      // Get the current day of the month
      const currentDay = currentDate.getDate();

      // Check if the input number is greater than the current day
      return number == currentDay;
    }

    // If it's not February, return false
    return false;
  }

  const tasks = [
    {
      day: 1,
      heading: "Contact us on Whatsapp.",
      task: 'Send a "Thank You" message to FT9ja on WhatsApp (+2349159480007) for supporting traders.💖🙌',
      link4: "https://wa.me/+2349159480007",
    },
    {
      day: 2,
      heading: "Follow and Subscribe.",
      task: "Follow and subscribe to us on TikTok, X (Twitter), and YouTube.📲",
      link3: "https://www.tiktok.com/@ft9ja",
      link2: "https://x.com/ft9ja_official",
      link1: "https://www.youtube.com/@ft9ja",
    },
    {
      day: 3,
      heading: "Complete Survey.",
      task: "Give us feedback—tell us what you love about FT9ja and what you’d like us to improve! 💬",
      link: "https://ft9ja.notion.site/18baa5a0912e8010bd0af3e80c3bd038",
    },
    {
      day: 4,
      heading: "Watch, Like and Comment.",
      task: "Watch, like, and comment on at least one video on our YouTube channel.🎥👍",
      link1: "https://www.youtube.com/@ft9ja",
    },
    {
      day: 5,
      heading: "Comment on Youtube.",
      task: 'Comment "FT9ja ❤️" under your favorite video on YouTube. 📝',
      link1: "https://www.youtube.com/@ft9ja",
    },
    {
      day: 6,
      heading: "Take Hero Quiz.",
      task: "Take the FT9ja Hero Quiz at ft9jahero.com. 🧠",
      link5: "https://ft9jahero.com/",
    },
    {
      day: 7,
      heading: "Post Screenshot on Socials.",
      task: "Share a screenshot of your FT9ja dashboard on X and TikTok & tag us. 📸",
    },
    {
      day: 8,
      heading: "Invite a friend.",
      task: "Invite a friend to join FT9ja and let us know! 🧑‍🤝‍🧑",
    },
    {
      day: 9,
      heading: "Tell us what you love.",
      task: "Tell us your favorite FT9ja feature and why. 💡",
      link4: "https://wa.me/+2349159480007",
    },
    {
      day: 10,
      heading: "Make a short video.",
      task: "Record a short video about why you love FT9ja and tag us! 🎥",
    },
    {
      day: 11,
      heading: "Share your trading setup.",
      task: "Take a selfie at your trading desk setup and share it with us. 📸",
    },
    {
      day: 12,
      heading: "Welcome to Hamma",
      task: "Imagine a trading competition for Nigerian stocks! 💡 Signup at",
      link6: "https://competition.gohamma.com/",
    },
    {
      day: 13,
      heading: "Welcome to hamma.trade",
      task: "Imagine a modern prop firm for Nigerian stocks! 💡 Signup at",
      link7: "https://www.hamma.trade/",
    },
    {
      day: 14,
      heading: "Show appreciation.",
      task: 'Send a "Thank You" message to your forex mentor for supporting you. Don\'t forget to mention FT9ja and let us know! 🙌',
    },
  ];

  const handleChange = async (e) => {
    const files = e.target.files; // Get all selected files

    if (files.length === 0) return; // Exit if no files are selected

    const maxFileSize = 10 * 1024 * 1024; // 10 MB in bytes
    const supportedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/heic",
      "video/mp4",
    ];

    setIsLoading(true); // Set loading state to true

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Validate file size
        if (file.size > maxFileSize) {
          console.error(`File ${file.name} exceeds the 10 MB limit.`);
          setIsLoading(false); // Set loading state to false
          continue; // Skip this file
        }

        // Validate file type
        if (!supportedFileTypes.includes(file.type)) {
          console.error(`File ${file.name} is not a supported file type.`);
          setIsLoading(false); // Set loading state to false
          continue; // Skip this file
        }

        const id = uuidv4();
        const storageRef = ref(storage, `images/${id}`); // Use file name as the path

        const uploadTask = uploadBytesResumable(storageRef, file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload of ${file.name} is ${progress}% done`);
            setProgress(progress); // Update progress state
            switch (snapshot.state) {
              case "paused":
                console.log(`Upload of ${file.name} is paused`);
                break;
              case "running":
                console.log(`Upload of ${file.name} is running`);
                break;
              default:
                console.log("No upload yet");
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error(`Error uploading ${file.name}:`, error);
            setIsLoading(false); // Set loading state to false on error
          },
          () => {
            // Handle successful uploads on complete
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log(`${file.name} is available at`, downloadURL);
              // Store the download URLs in an array
              setImgUrl((prevUrls) => [
                ...prevUrls,
                { name: file.name, url: downloadURL },
              ]);

              // If this is the last file, set loading to false
              if (i === files.length - 1) {
                setIsLoading(false);
              }
            });
          }
        );
      }
    } catch (error) {
      console.error("Error during upload:", error);
      setIsLoading(false); // Set loading state to false on error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    const proofOfAdvocacy1 = imgUrl[0]?.url;
    const proofOfAdvocacy2 = imgUrl[1]?.url || "";
    const proofOfAdvocacy3 = imgUrl[2]?.url || "";
    const firstName = participantInfo.firstName?.trim();
    const lastName = participantInfo.lastName?.trim();
    const email = participantInfo.email?.trim();
    const phoneNumber = "";
    const nationality = selectedDay.toString() || "";

    // Check for missing required fields
    if (!proofOfAdvocacy1 || !firstName || !lastName || !email) {
      setError(
        "Please fill out all required fields and upload your proofs of activity."
      );
      setSending(false);
      return;
    }

    const post = {
      proofOfAdvocacy1,
      proofOfAdvocacy2,
      proofOfAdvocacy3,
      firstName,
      lastName,
      email,
      phoneNumber,
      nationality,
      code,
    };

    try {
      await axios.post(
        "https://promo-c4fbea1ac932.herokuapp.com/api/promo",
        post
      );

      setParticippantInfo({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        nationality: "",
      });
      setImgUrl([]);
      setProgress(null);
      navigate(`/success?name=${firstName}&email=${email}`);
    } catch (error) {
      console.log(error);
      // alert(
      //   "An error occurred. Please ensure that all required fields are filled and try again."
      // );
      // window.location.reload();
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="bg-[url('https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1738167477/Layer_1_pjuvas.webp')] bg-no-repeat bg-cover pb-9 relative font-inter">
      <div>
        <div className="sticky top-1 mt-1 w-full px-1 z-10 flex flex-col items-center">
          <img
            className="h-[150px] lg:h-[200px] w-full object-cover"
            src="https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1738337958/Banner_zfiawa.webp"
            alt=""
          />
          <div className="font-inter absolute lg:bottom-16 bottom-7">
            <p className="font-semibold text-[2rem] text-[#FFFFFF] font-inter mb-2.5 text-center">
              FT9ja’s <span className="text-[#FFF15A]">Val Promo</span>
            </p>
            <p className="text-[#FFFFFF] lg:text-[1.5rem] text-center">
              Complete fun and simple tasks and upload proof to win exciting
              prizes
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 gap-8 lg:mx-9 mx-4 mt-9">
          {tasks.map((task) => (
            <div
              className={`bg-white p-6 rounded h-[300px] shadow-2xl flex flex-col ${
                isNumberGreaterThanCurrentDayInFebruary(task.day) && "blur-sm"
              }`}
            >
              <div className="flex items-center justify-between">
                <p className="text-[#16161699] text-[0.875rem]">
                  Day {task.day}
                </p>
                <div className="bg-[#0FB23C1A] flex items-center gap-2 p-1 rounded-md">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6005_48403)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.44782 0.435824L5.31782 1.56482C5.22586 1.65502 5.10263 1.70625 4.97382 1.70782H3.19582C2.37382 1.70782 1.70782 2.37382 1.70782 3.19582V4.97382C1.70651 5.10298 1.65526 5.22661 1.56482 5.31882L0.434824 6.44782C0.156366 6.72712 0 7.10543 0 7.49982C0 7.89422 0.156366 8.27253 0.434824 8.55182L1.56482 9.68182C1.65502 9.77379 1.70625 9.89702 1.70782 10.0258V11.8038C1.70782 12.6258 2.37382 13.2918 3.19582 13.2918H4.97382C5.10298 13.2931 5.22661 13.3444 5.31882 13.4348L6.44782 14.5648C6.72712 14.8433 7.10543 14.9996 7.49982 14.9996C7.89422 14.9996 8.27253 14.8433 8.55182 14.5648L9.68182 13.4348C9.77379 13.3446 9.89702 13.2934 10.0258 13.2918H11.8038C12.6258 13.2918 13.2918 12.6258 13.2918 11.8038V10.0258C13.2931 9.89667 13.3444 9.77304 13.4348 9.68082L14.5648 8.55182C14.8433 8.27253 14.9996 7.89422 14.9996 7.49982C14.9996 7.10543 14.8433 6.72712 14.5648 6.44782L13.4348 5.31782C13.3446 5.22586 13.2934 5.10263 13.2918 4.97382V3.19582C13.2918 2.37382 12.6258 1.70782 11.8038 1.70782H10.0258C9.89667 1.70651 9.77304 1.65526 9.68082 1.56482L8.55182 0.434824C8.27253 0.156366 7.89422 0 7.49982 0C7.10543 0 6.72712 0.157366 6.44782 0.435824ZM4.64582 9.64482L9.64582 4.64482L10.3538 5.35282L5.35382 10.3528L4.64582 9.64482ZM4.99982 4.99982V5.99982H5.99982V4.99982H4.99982ZM8.99982 9.99982H9.99982V8.99982H8.99982V9.99982Z"
                        fill="#0FB23C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6005_48403">
                        <rect width="15" height="15" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p className="text-[#018B28] text-[0.875rem]">
                    +50% Discount x {task.day}
                  </p>
                </div>
              </div>
              <div>
                <h1 className="my-6 text-[#151515] font-semibold">
                  {task.heading}
                </h1>
                <p className="text-[#16161699] text-[0.875rem]">{task.task}</p>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="flex flex-col gap-2 text-[0.75rem] text-[#16161699] mt-4"
                >
                  {task.link1 && (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                      href={task.link1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to go to Youtube channel.
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="M885 512q-155 0-294 58T342 737Q233 846 175 985t-58 295q0 106 27 204t78 183t120 156t155 120t184 77t204 28h896v-128H885q-88 0-170-23t-153-64t-129-100t-100-130t-65-153t-23-170t23-170t64-153t100-129t130-100t153-65t170-23h821l-426 427l74 74l566-565l-566-565l-74 74l426 427z"
                        />
                      </svg>
                    </a>
                  )}
                  {task.link2 && (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                      href={task.link2}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to go to X page.
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="M885 512q-155 0-294 58T342 737Q233 846 175 985t-58 295q0 106 27 204t78 183t120 156t155 120t184 77t204 28h896v-128H885q-88 0-170-23t-153-64t-129-100t-100-130t-65-153t-23-170t23-170t64-153t100-129t130-100t153-65t170-23h821l-426 427l74 74l566-565l-566-565l-74 74l426 427z"
                        />
                      </svg>
                    </a>
                  )}
                  {task.link3 && (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                      href={task.link3}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to go to TikTok page.
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="M885 512q-155 0-294 58T342 737Q233 846 175 985t-58 295q0 106 27 204t78 183t120 156t155 120t184 77t204 28h896v-128H885q-88 0-170-23t-153-64t-129-100t-100-130t-65-153t-23-170t23-170t64-153t100-129t130-100t153-65t170-23h821l-426 427l74 74l566-565l-566-565l-74 74l426 427z"
                        />
                      </svg>
                    </a>
                  )}
                  {task.link && (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                      href={task.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to go to Survey.
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="M885 512q-155 0-294 58T342 737Q233 846 175 985t-58 295q0 106 27 204t78 183t120 156t155 120t184 77t204 28h896v-128H885q-88 0-170-23t-153-64t-129-100t-100-130t-65-153t-23-170t23-170t64-153t100-129t130-100t153-65t170-23h821l-426 427l74 74l566-565l-566-565l-74 74l426 427z"
                        />
                      </svg>
                    </a>
                  )}
                  {task.link4 && (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                      href={task.link4}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to go to Whatsapp.
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="M885 512q-155 0-294 58T342 737Q233 846 175 985t-58 295q0 106 27 204t78 183t120 156t155 120t184 77t204 28h896v-128H885q-88 0-170-23t-153-64t-129-100t-100-130t-65-153t-23-170t23-170t64-153t100-129t130-100t153-65t170-23h821l-426 427l74 74l566-565l-566-565l-74 74l426 427z"
                        />
                      </svg>
                    </a>
                  )}
                  {task.link5 && (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                      href={task.link5}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to answer quiz.
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="M885 512q-155 0-294 58T342 737Q233 846 175 985t-58 295q0 106 27 204t78 183t120 156t155 120t184 77t204 28h896v-128H885q-88 0-170-23t-153-64t-129-100t-100-130t-65-153t-23-170t23-170t64-153t100-129t130-100t153-65t170-23h821l-426 427l74 74l566-565l-566-565l-74 74l426 427z"
                        />
                      </svg>
                    </a>
                  )}
                  {task.link6 && (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                      href={task.link6}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to go to Hamma competition.
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="M885 512q-155 0-294 58T342 737Q233 846 175 985t-58 295q0 106 27 204t78 183t120 156t155 120t184 77t204 28h896v-128H885q-88 0-170-23t-153-64t-129-100t-100-130t-65-153t-23-170t23-170t64-153t100-129t130-100t153-65t170-23h821l-426 427l74 74l566-565l-566-565l-74 74l426 427z"
                        />
                      </svg>
                    </a>
                  )}
                  {task.link7 && (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                      href={task.link7}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to go to Hamma prop firm.
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 2048 2048"
                      >
                        <path
                          fill="currentColor"
                          d="M885 512q-155 0-294 58T342 737Q233 846 175 985t-58 295q0 106 27 204t78 183t120 156t155 120t184 77t204 28h896v-128H885q-88 0-170-23t-153-64t-129-100t-100-130t-65-153t-23-170t23-170t64-153t100-129t130-100t153-65t170-23h821l-426 427l74 74l566-565l-566-565l-74 74l426 427z"
                        />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
              <div className="mt-auto">
                {behind(task.day) ? (
                  <div className="flex items-center gap-2">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 27.2267L3.72 18.9467L7.49333 15.1733L12 19.6933L25.1733 6.50665L28.9467 10.28L12 27.2267Z"
                        fill="#0FB23C"
                      />
                    </svg>
                    <p className="text-[0.75rem] font-inter text-[#16161699]">
                      This task has been completed
                    </p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      current(task.day) && setOpenUpload(true);
                      setSelectedDay(task.day);
                    }}
                    className="flex items-center gap-3 cursor-pointer"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.6666 16C18.9866 16 16 18.9866 16 22.6666C16 26.3466 18.9866 29.3333 22.6666 29.3333C26.3466 29.3333 29.3333 26.3466 29.3333 22.6666C29.3333 18.9866 26.3466 16 22.6666 16ZM24.8666 25.8L22 22.9333V18.6666H23.3333V22.3866L25.8 24.8533L24.8666 25.8ZM24 3.99998H19.76C19.2 2.45331 17.7333 1.33331 16 1.33331C14.2666 1.33331 12.8 2.45331 12.24 3.99998H7.99998C6.53331 3.99998 5.33331 5.19998 5.33331 6.66665V26.6666C5.33331 28.1333 6.53331 29.3333 7.99998 29.3333H16.1466C15.3568 28.5685 14.715 27.6645 14.2533 26.6666H7.99998V6.66665H10.6666V10.6666H21.3333V6.66665H24V13.44C24.9466 13.5733 25.84 13.8533 26.6666 14.24V6.66665C26.6666 5.19998 25.4666 3.99998 24 3.99998ZM16 6.66665C15.2666 6.66665 14.6666 6.06665 14.6666 5.33331C14.6666 4.59998 15.2666 3.99998 16 3.99998C16.7333 3.99998 17.3333 4.59998 17.3333 5.33331C17.3333 6.06665 16.7333 6.66665 16 6.66665Z"
                        fill="#646464"
                      />
                    </svg>
                    <p className="flex flex-col text-[0.75rem] text-[#16161699]">
                      Click to upload proof
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/40" />

        <div className="fixed inset-0 flex w-screen items-center justify-center lg:p-4">
          <DialogPanel className="max-w-lg space-y-4 border bg-white">
            <div
              className="relative flex flex-col items-center"
              onClick={() => setIsOpen(false)}
            >
              <img
                className="h-[90vh] w-[100%] lg:h-[700px] max-h-[900px]"
                src="https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1738328210/FT9ja_Val_s_Interstitial_q7zw3b.webp"
                alt=""
              />
              <Link to={"/hiw"} className="absolute bottom-6">
                <p className="flex items-center gap-2 font-semibold border border-black p-1 rounded-lg">
                  How it works{" "}
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5001 18.3333C15.1025 18.3333 18.8334 14.6023 18.8334 9.99996C18.8334 5.39759 15.1025 1.66663 10.5001 1.66663C5.89771 1.66663 2.16675 5.39759 2.16675 9.99996C2.16675 14.6023 5.89771 18.3333 10.5001 18.3333Z"
                      stroke="#161616"
                      stroke-width="1.66667"
                    />
                    <path
                      d="M10.5 5.83325H10.5083"
                      stroke="#161616"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                    />
                    <path
                      d="M8.8335 9.16663H10.5002V13.3333M8.8335 13.3333H12.1668"
                      stroke="#161616"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </Link>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/40" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="max-w-lg space-y-4 border bg-white rounded-md font-inter">
            <div className="p-6 text-center">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-auto mb-6 cursor-pointer"
                onClick={() => setOpenUpload(false)}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.425 5.25833L15.2416 4.07501L9.99995 9.32501L4.75827 4.07501L3.57495 5.25833L8.82495 10.5L3.57495 15.7417L4.75827 16.925L9.99995 11.675L15.2416 16.925L16.425 15.7417L11.175 10.5L16.425 5.25833Z"
                  fill="black"
                />
              </svg>

              <h1 className="font-semibold text-[#161616] text-[1.25rem] mb-4">
                Upload Proof
              </h1>
              <p className="text-[#16161699] text-[0.875rem] mb-6">
                Upload a screenshot or screen recording of you completing the
                task to qualify to win{" "}
              </p>
              <div className="flex flex-col lg:flex-row items-center gap-6 w-full">
                <div className="flex flex-col items-start w-full">
                  <label
                    className="text-[#16161699] text-[0.875rem]"
                    htmlFor=""
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="border border-[#d8d8d8] py-2 px-1 rounded-lg w-full outline-none"
                    value={participantInfo.firstName}
                    onChange={(e) =>
                      setParticippantInfo({
                        ...participantInfo,
                        firstName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex flex-col items-start w-full">
                  <label
                    className="text-[#16161699] text-[0.875rem]"
                    htmlFor=""
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="border border-[#d8d8d8] py-2 px-1 rounded-lg w-full outline-none"
                    value={participantInfo.lastName}
                    onChange={(e) =>
                      setParticippantInfo({
                        ...participantInfo,
                        lastName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col items-start w-full my-6">
                <label className="text-[#16161699] text-[0.875rem]" htmlFor="">
                  Email
                </label>
                <input
                  className="border w-full border-[#d8d8d8] py-2 px-1 rounded-lg outline-none"
                  type="text"
                  value={participantInfo.email}
                  onChange={(e) =>
                    setParticippantInfo({
                      ...participantInfo,
                      email: e.target.value,
                    })
                  }
                />
              </div>
              {isLoading ? (
                <div className="flex items-center gap-2 justify-center text-center">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="#E11A22"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <path
                        stroke-dasharray="2 4"
                        stroke-dashoffset="6"
                        d="M12 21c-4.97 0 -9 -4.03 -9 -9c0 -4.97 4.03 -9 9 -9"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          dur="0.6s"
                          repeatCount="indefinite"
                          values="6;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="32"
                        stroke-dashoffset="32"
                        d="M12 3c4.97 0 9 4.03 9 9c0 4.97 -4.03 9 -9 9"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.1s"
                          dur="0.4s"
                          values="32;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="10"
                        stroke-dashoffset="10"
                        d="M12 16v-7.5"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.5s"
                          dur="0.2s"
                          values="10;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="6"
                        stroke-dashoffset="6"
                        d="M12 8.5l3.5 3.5M12 8.5l-3.5 3.5"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.7s"
                          dur="0.2s"
                          values="6;0"
                        />
                      </path>
                    </g>
                  </svg>{" "}
                  <p className="text-[0.875rem]">Please wait...</p>
                </div>
              ) : imgUrl.length > 0 ? (
                <div>
                  {imgUrl.map((url, index) => (
                    <div className="flex items-center justify-between bg-[#F1F1F1] rounded-lg border border-[#D8D8D8] p-4 mb-4">
                      <p>{url.name}</p>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                        onClick={() => {
                          const updatedItems = imgUrl.filter(
                            (item) => item.url !== url.url
                          );
                          // Update the state
                          setImgUrl(updatedItems);
                        }}
                      >
                        <g clip-path="url(#clip0_6005_69253)">
                          <path
                            d="M2.5 6.25L2.5 18.75C2.5 19.4375 3.0625 20 3.75 20L15 20C15.6875 20 16.25 19.4375 16.25 18.75L16.25 6.25L2.5 6.25ZM6.25 17.5H5L5 8.75H6.25L6.25 17.5ZM8.75 17.5H7.5L7.5 8.75H8.75L8.75 17.5ZM11.25 17.5H10L10 8.75H11.25L11.25 17.5ZM13.75 17.5H12.5L12.5 8.75H13.75L13.75 17.5ZM16.5625 2.5L12.5 2.5V0.9375C12.499 0.689164 12.3999 0.45128 12.2243 0.275678C12.0487 0.100077 11.8108 0.000987433 11.5625 0L7.1875 0C6.93916 0.000987433 6.70128 0.100077 6.52568 0.275678C6.35008 0.45128 6.25099 0.689164 6.25 0.9375V2.5L2.1875 2.5C1.93886 2.5 1.7004 2.59877 1.52459 2.77459C1.34877 2.9504 1.25 3.18886 1.25 3.4375L1.25 5L17.5 5V3.4375C17.5 3.18886 17.4012 2.9504 17.2254 2.77459C17.0496 2.59877 16.8111 2.5 16.5625 2.5ZM11.25 2.5L7.5 2.5V1.26625L11.25 1.26625V2.5Z"
                            fill="#161616"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_6005_69253">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  ))}
                </div>
              ) : (
                <label
                  className="bg-[#F1F1F1] rounded-lg border border-[#D8D8D8] py-[17.5px] flex items-center justify-center"
                  htmlFor="val-2025"
                >
                  Upload File{" "}
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00008 13.8333L8.00008 8.83333H4.66675L10.5001 3L16.3334 8.83333H13.0001V13.8333L8.00008 13.8333ZM4.66675 17.1667L4.66675 15.5L16.3334 15.5V17.1667H4.66675Z"
                      fill="#161616"
                    />
                  </svg>
                </label>
              )}
              <input
                onChange={handleChange}
                className="hidden"
                multiple
                type="file"
                name=""
                id="val-2025"
              />
              {imgUrl.length < 1 && (
                <p className="text-[#16161699] text-[0.875rem] mb-4 mt-6">
                  Max File Size: 10mb
                </p>
              )}
              {imgUrl.length < 1 && (
                <p className="text-[#16161699] text-[0.875rem]">
                  Supported file type: JPG, PNG, HEIC, MP4
                </p>
              )}

              <p className="text-[#E11A22] text-[0.875rem] text-center">
                {error}
              </p>

              {sending ? (
                <div className="flex items-center justify-center gap-5">
                  {" "}
                  <span className="text-[0.875rem] text-[#16161699]">
                    Sending...
                  </span>
                  <ReactLoading
                    type="spin"
                    color="#0FB23C"
                    width={30}
                    height={30}
                  />
                </div>
              ) : (
                imgUrl.length > 0 && (
                  <button
                    onClick={handleSubmit}
                    className="my-4 p-2.5 bg-[#0FB23C] text-white rounded-lg"
                  >
                    Submit
                  </button>
                )
              )}
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default NewHome;
