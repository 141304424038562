import {
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

function Success() {
  const shareUrl = "https://promo.ft9ja.com/";

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("name"); // Replace 'param1' with your query parameter name
  const param2 = queryParams.get("email");
  // console.log(param1)
  // console.log(param2)

  useEffect(() => {
    const timer = setTimeout(() => {
      const url = `https://dashboard.ft9ja.com/challenge?promo=yes${param1 ? `&name=${param1}` : ''}${param2 ? `&email=${param2}` : ''}`; // Replace with your target URL
      window.location.href = url; // Redirects the user to the URL
    }, 2000); // 500ms = 0.5 seconds

    // Cleanup function to clear the timer if the component is unmounted before 5 seconds
    return () => clearTimeout(timer);
  }, [param1, param2]);

  return (
    <div className="w-full h-[100vh] flex flex-col items-center justify-start lg:gap-28 gap-5 relative pt-10">
      <Link to="/" className="absolute lg:top-10 lg:left-10 left-3 top-3">
        <button className="md:w-[155px] w-[110px] md:h-[48px] h-[35px] bg-[#359602] text-white rounded-[5px] lg:text-[18px] text-[14px] font-bold">
          Back to Home
        </button>
      </Link>
      <div className="flex lg:flex-row flex-col-reverse items-center">
        <h1 className="font-open font-bold text-3xl mt-5 text-center">
          Thanks for participating in FT9ja Valentine's day Promo!
        </h1>
        <img
          src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681732194/confetti-svgrepo-com_1_jp4czv.png"
          alt=""
          className="lg:w-[55px] lg:h-[55px] w-[100px] h-[100px]"
        />
      </div>
      <div className="flex flex-col items-center">
        <img
          src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681733505/animation_500_lgf8yqp4_ap45x2.gif"
          alt=""
          className="lg:w-[272px] lg:h-[272px] w-[150px] h-[150px]"
        />
        <h1 className="font-open text-lg font-normal text-center md:w-[500px] w-[300px] z-10 bg-black bg-opacity-30 p-1 md:bg-transparent">
          Your submission was successful! You are now being redirected to the
          FT9ja dashboard to access your discount code
        </h1>
      </div>
      <div className="flex flex-col items-center lg:gap-8 gap-4">
        <h1 className="font-open text-lg font-bold">Share to:</h1>
        <div className="flex items-center gap-6">
          <CopyToClipboard
            text={shareUrl}
            onCopy={() => alert("Copied to Clipboard")}
          >
            <button>
              <img
                src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681739490/Group_51_k3c9oj.png"
                alt=""
                className="w-[35px]"
              />
            </button>
          </CopyToClipboard>
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon className="rounded-full" size={35} />
          </WhatsappShareButton>
          <TwitterShareButton url={shareUrl}>
            <TwitterIcon className="rounded-full" size={35} />
          </TwitterShareButton>
        </div>
      </div>
      <img
        src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681732195/confetti-svgrepo-com_2_gsnivw.png"
        alt=""
        className="lg:w-[420px] lg:h-[420px] w-[150px] h-[150px] absolute bottom-0 left-0"
      />
      <img
        src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681732195/confetti-svgrepo-com_3_gpzzgs.png"
        alt=""
        className="lg:w-[420px] lg:h-[420px] w-[150px] h-[150px] absolute bottom-0 right-0"
      />
    </div>
  );
}

export default Success;
