import React, { useEffect, useState } from "react";
import axios from "axios";

function Right() {
  const [dDay, setDDay] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [participants, setParticipants] = useState([]);
  console.log(dDay);
  // console.log(participants);

  useEffect(() => {
    const target = new Date("02/14/2025 18:00:00");

    const interval = setInterval(() => {
      const now = new Date();
      const difference = target.getTime() - now.getTime();

      const d = Math.floor(difference / (1000 * 60 * 60 * 24));
      setDays(d);

      const h = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      setHours(h);

      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      setMinutes(m);

      const s = Math.floor((difference % (1000 * 60)) / 1000);
      setSeconds(s);

      if (d <= 0 && h <= 0 && m <= 0 && s <= 0) {
        setDDay(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://promo-c4fbea1ac932.herokuapp.com/api/promo/participants"
        );
        setParticipants(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="px-3">
      {dDay === true ? (<h1 className="text-2xl text-center">We are no longer accepting entries...</h1>) : (<div className="flex gap-3">
        <p className="flex flex-col items-center font-plus text-sm font-medium gap-2">
          <span className="border w-[55px] h-[55px] flex items-center justify-center rounded-[5px] text-xl border-[#CBCBCB]">
            {days}
          </span>
          Days{" "}
        </p>
        <span className="text-5xl text-gray-500">:</span>
        <p className="flex flex-col items-center font-plus text-sm font-medium gap-2">
          <span className="border w-[55px] h-[55px] flex items-center justify-center rounded-[5px] text-xl border-[#CBCBCB]">
            {hours}
          </span>
          Hours
        </p>
        <span className="text-5xl text-gray-500">:</span>
        <p className="flex flex-col items-center font-plus text-sm font-medium gap-2">
          <span className="border w-[55px] h-[55px] flex items-center justify-center rounded-[5px] text-xl border-[#CBCBCB]">
            {minutes}
          </span>
          Minutes
        </p>
        <span className="text-5xl text-gray-500">:</span>
        <p className="flex flex-col items-center font-plus text-sm font-medium gap-2">
          <span className="border w-[55px] h-[55px] flex items-center justify-center rounded-[5px] text-xl border-[#CBCBCB]">
            {seconds}
          </span>
          Seconds
        </p>
      </div>)}
      <div className="my-[85px]">
        <h1 className="text-base font-plus font-semibold mb-4">
          Total no of Participants
        </h1>
        <div className="w-[347px] h-[63px] bg-[#E9E9E9] flex items-center rounded-[10px] font-plus font-semibold text-xl pl-4">
          {participants.length}
        </div>
      </div>
      <div>
        <h1 className="font-plus text-base font-semibold mb-4">Participants</h1>
        <div className="shadow-[0_0_4px_rgba(0,0,0,0.25)] py-4 flex flex-col gap-7 h-96 max-h-96 overflow-y-scroll">
          {participants.map((participant, index) => (
            <p
              className="flex gap-2 items-center border-b pl-4 pb-5"
              key={participant._id}
            >
              <span className="bg-[#00800022] flex items-center justify-center rounded-full text-sm font-plus font-bold h-[24px] w-[24px]">
                {index + 1}
              </span>
              {participant.firstName} {participant.lastName}
            </p>
          ))}

          {/* <p className="flex gap-2 items-center border-b pl-4 pb-5">
            <span className="bg-[#00800022] flex items-center justify-center rounded-full text-sm font-plus font-bold h-[24px] w-[24px]">
              2
            </span>
            Adeboyega Akintoye
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Right;
