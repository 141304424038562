import React from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Link } from "react-router-dom";

function HIW() {
  return (
    <div className="bg-[url('https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1738167477/Layer_1_pjuvas.webp')] bg-no-repeat bg-cover pb-9 relative font-inter">
      <div>
        <div className="sticky top-1 mt-1 w-full px-1 z-10 flex flex-col items-center">
          <img
            className="h-[150px] lg:h-[200px] w-full object-cover"
            src="https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1738337958/Banner_zfiawa.webp"
            alt=""
          />
          <div className="font-inter absolute lg:bottom-16 bottom-7">
            <p className="font-semibold text-[2rem] text-[#FFFFFF] font-inter mb-2.5 text-center">
              FT9ja’s <span className="text-[#FFF15A]">Val Promo</span>
            </p>
            <p className="text-[#FFFFFF] text-[1.5rem] text-center">
              How It Works
            </p>
          </div>
        </div>

        <div className="w-[95%] lg:w-[70%] mx-auto mt-8 text-[0.875rem] lg:text-[1rem]">
          <p>
            💘 14 Days of Love with FT9ja is our special Valentine’s promo,
            where we’re giving away 50% discounts on our $10,000 prop challenge
            accounts—with the giveaway growing bigger every day!
          </p>
          <p className="my-5">Here’s how it works:</p>

          <div className="mb-5">
            1️⃣ Daily Increasing Giveaway: <br />
            <ul className="list-disc pl-16">
              <li>
                On <b>Day 1</b>, we give away <b>1 discount code</b>.
              </li>
              <li>
                On <b>Day 2</b>, we give away <b>2 discount codes</b>.
              </li>
              <li>
                This continues until <b>Day 14</b>, where{" "}
                <b>14 lucky traders</b> receive a discount—totaling{" "}
                <b>105 winners over 14 days!</b>
              </li>
            </ul>
          </div>
          <div className="mb-5">
            2️⃣ How to Participate: <br />
            <ul className="list-disc pl-16">
              <li>
                Check your email or follow our social media for daily promo
                tasks.
              </li>
              <li>
                Complete the daily task to qualify for that day’s giveaway.
              </li>
              <li>
                Winners are selected and sent their exclusive{" "}
                <b>50% discount code</b>
                via email.
              </li>
            </ul>
          </div>
          <div className="mb-5">
            3️⃣ Redeeming Your Discount Code: <br />
            <ul className="list-disc pl-16">
              <li>
                Once you receive your discount code, <b>apply it at checkout</b>{" "}
                when purchasing the $10,000 prop challenge account.
              </li>
              <li>
                Enjoy <b>50% off</b> instantly
              </li>
              <li>The code is valid for a limited time, so don’t miss out!</li>
            </ul>
          </div>
          <div>
            <p>
              <b>📅 Promo Duration</b>: February 1 – February 14
            </p>
          </div>
        </div>

        <div className="mx-auto bg-white rounded-lg w-[95%] lg:w-[70%] mt-8 p-4 shadow-lg">
          <p className="text-center text-[2rem] font-semibold mb-4">FAQ</p>
          <div className="flex flex-col gap-2 text-[0.875rem] lg:text-[1rem]">
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center gap-2 justify-between border border-[#D8D8D8] bg-[#F1F1F1] w-full rounded-lg lg:p-4 p-2">
                    Who can participate in the Valentine’s promo?
                    {/* <ChevronDownIcon className={clsx('w-5', open && 'rotate-180')} /> */}
                    <svg
                      className={`${open ? "rotate-l80" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m6 9l6 6l6-6"
                      />
                    </svg>
                  </DisclosureButton>
                  <DisclosurePanel className="py-2 px-5">
                    Anyone! Whether you’re a new or existing FT9ja trader, you
                    can join by completing the daily tasks and securing your
                    discount.
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center gap-2 justify-between border border-[#D8D8D8] bg-[#F1F1F1] w-full rounded-lg lg:p-4 p-2">
                    How are winners selected?
                    {/* <ChevronDownIcon className={clsx('w-5', open && 'rotate-180')} /> */}
                    <svg
                      className={`${open ? "rotate-l80" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m6 9l6 6l6-6"
                      />
                    </svg>
                  </DisclosureButton>
                  <DisclosurePanel className="py-2 px-5">
                    Each day, the required number of winners ([X] on Day X) will
                    be chosen from those who successfully complete the daily
                    task. Winners are notified via email.
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center gap-2 justify-between border border-[#D8D8D8] bg-[#F1F1F1] w-full rounded-lg lg:p-4 p-2">
                    Can I win more than once?
                    {/* <ChevronDownIcon className={clsx('w-5', open && 'rotate-180')} /> */}
                    <svg
                      className={`${open ? "rotate-l80" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m6 9l6 6l6-6"
                      />
                    </svg>
                  </DisclosureButton>
                  <DisclosurePanel className="py-2 px-5">
                    Yes! You can participate every day. If you win once, you can
                    still complete tasks on other days for another chance to
                    win.
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center gap-2 justify-between border border-[#D8D8D8] bg-[#F1F1F1] w-full rounded-lg lg:p-4 p-2">
                    How will I receive my discount code?
                    {/* <ChevronDownIcon className={clsx('w-5', open && 'rotate-180')} /> */}
                    <svg
                      className={`${open ? "rotate-l80" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m6 9l6 6l6-6"
                      />
                    </svg>
                  </DisclosureButton>
                  <DisclosurePanel className="py-2 px-5">
                    If you’re selected as a winner, you’ll receive an email with
                    your exclusive 50% discount code and instructions on how to
                    use it.
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center gap-2 justify-between border border-[#D8D8D8] bg-[#F1F1F1] w-full rounded-lg lg:p-4 p-2">
                    How long is my discount code valid?
                    {/* <ChevronDownIcon className={clsx('w-5', open && 'rotate-180')} /> */}
                    <svg
                      className={`${open ? "rotate-l80" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m6 9l6 6l6-6"
                      />
                    </svg>
                  </DisclosureButton>
                  <DisclosurePanel className="py-2 px-5">
                    The discount codes have a limited redemption period. Make
                    sure to use yours before it expires!
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center gap-2 justify-between border border-[#D8D8D8] bg-[#F1F1F1] w-full rounded-lg lg:p-4 p-2">
                    What happens if I miss a day?
                    {/* <ChevronDownIcon className={clsx('w-5', open && 'rotate-180')} /> */}
                    <svg
                      className={`${open ? "rotate-l80" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m6 9l6 6l6-6"
                      />
                    </svg>
                  </DisclosureButton>
                  <DisclosurePanel className="py-2 px-5">
                    Each day is a fresh opportunity! If you miss a day, you can
                    still participate the next day. The number of discount codes
                    increases daily, so your chances improve the longer you stay
                    engaged.
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center gap-2 justify-between border border-[#D8D8D8] bg-[#F1F1F1] w-full rounded-lg lg:p-4 p-2">
                    Can I transfer my discount code to someone else?
                    {/* <ChevronDownIcon className={clsx('w-5', open && 'rotate-180')} /> */}
                    <svg
                      className={`${open ? "rotate-l80" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m6 9l6 6l6-6"
                      />
                    </svg>
                  </DisclosureButton>
                  <DisclosurePanel className="py-2 px-5">
                    No, discount codes are non-transferable and can only be used
                    by the recipient.
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center gap-2 justify-between border border-[#D8D8D8] bg-[#F1F1F1] w-full rounded-lg lg:p-4 p-2">
                    Where can I check for daily tasks and winner announcements?
                    {/* <ChevronDownIcon className={clsx('w-5', open && 'rotate-180')} /> */}
                    <svg
                      className={`${open ? "rotate-l80" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m6 9l6 6l6-6"
                      />
                    </svg>
                  </DisclosureButton>
                  <DisclosurePanel className="py-2 px-5">
                    Stay updated through: <br />
                    ✅ Your email inbox <br />
                    ✅ FT9ja’s social media pages <br />✅ The FT9ja Promo page:
                    promo.ft9ja.com
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center gap-2 justify-between border border-[#D8D8D8] bg-[#F1F1F1] w-full rounded-lg lg:p-4 p-2">
                    I received a discount code but I’m having trouble using it.
                    What should I do?
                    {/* <ChevronDownIcon className={clsx('w-5', open && 'rotate-180')} /> */}
                    <svg
                      className={`${open ? "rotate-l80" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m6 9l6 6l6-6"
                      />
                    </svg>
                  </DisclosureButton>
                  <DisclosurePanel className="py-2 px-5">
                    No worries! Just reply to your email or contact our support
                    team, and we’ll assist you in redeeming your discount.
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
          </div>
        </div>

        <div className="w-[95%] lg:w-[70%] mx-auto mt-8 text-[0.875rem] lg:text-[1rem] mb-10">
          💘 Don’t Miss Out on the Love! 💘 This is your chance to trade big at
          half the cost! Stay engaged, complete daily tasks, and claim your 50%
          discount before the promo ends on February 14! <br /> <br />
          🔗 <Link to={"/"}>Join the Valentine’s Promo Now 🚀</Link>
        </div>
      </div>
    </div>
  );
}

export default HIW;
