import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Home from "./pages/Home";
import Promo from "./pages/Promo";
import Success from "./pages/Success";
import NewHome from "./pages/NewHome";
import HIW from "./pages/HIW";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NewHome />} />
        <Route path="/promo" element={<Promo />} />
        <Route path="/success" element={<Success />} />
        <Route path="/hiw" element={<HIW />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
